<template>
  <div>
    <el-form :model="form"
             label-width="80px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="课室名称">
            <el-input clearable
                      style="width:100%"
                      placeholder="请输入"
                      v-model="form.room_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="addClassRoom">新增</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <EditForm ref="EditFormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import EditForm from './components/editForm.vue'
export default {
  data () {
    return {
      form: {
        room_name: '',
      },
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'cz_classes_classroom_admin',
      tableColumns: [
        { prop: 'id', align: 'center', label: '编号', width: '80', showToopic: false },
        { prop: 'room_name', align: 'center', label: '课室名称', width: '', showToopic: false },
        { prop: 'notice', align: 'center', label: '公告', width: '', showToopic: false },
        { prop: 'status', align: 'center', label: '状态', width: '100', showToopic: false, isStatus: true },

        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      schoolList: [],
      gradeList: [],
    }
  },
  components: {
    Form, EditForm
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      if (prop == 'status') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '开启' : '关闭'
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v1/special/room_lst',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    editRow (row) {
      let form = {
        id: row.id,
        room_name: row.room_name,
        notice: row.notice,
        status: row.status
      }
      this.$refs.EditFormRef.form = form
      this.$refs.EditFormRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/special/room_del',
          method: 'post',
          data: {
            id: row.id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    addClassRoom () {
      let form = {
        class_name: '',
        grade_id: '',
        end_time: '',
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    showForm (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
